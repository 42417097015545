import React from 'react'

import SeoBlock from '../components/Layout/seo'

const NotFoundPage = () => (
  <div style={{background: 'url(/images/nosignal.gif) no-repeat center center fixed', backgroundSize: 'cover', overflow: 'hidden', height: 550, display: 'flex', justifyContent: 'center', margin: 5, boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'}}>
    <SeoBlock title="404: Not found" />
    <h1 style={{color: 'white', fontSize: '1500%', margin: '150px 0'}}>404</h1>
    <h1 style={{fontSize: '300%', margin: '50px 0'}}>NOT FOUND</h1>
    <p style={{color: 'white', fontSize: '150%', fontWeight: 700}}>You just hit a route that doesn't exist...</p>
  </div>
)

export default NotFoundPage